<template>
	<!-- <section id="colorlib-hero" class="js-fullheight" data-section="home">
		<div class="flexslider js-fullheight">
			<ul class="slides">
				<li style="background-image: url(images/img_bg_1.jpg);">
					<div class="overlay"></div>
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
								<div class="slider-text-inner js-fullheight">
									<div class="desc">
										<h1>Hi! <br>I'm Tanyo</h1>
										<h2>IT Professional/Developer since 2010</h2>
										<p><a class="btn btn-primary btn-learn">Download CV <i class="icon-download4"></i></a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
				<li style="background-image: url(images/img_bg_2.jpg);">
				<li>
					<div class="overlay"></div>
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
								<div class="slider-text-inner">
									<div class="desc">
										<h1>I am <br>a Software Pro</h1>
										<h2>The Old and the Newest dotnet Stacks, Contairenisation, SPA, IoT, Servers, 3D Printing I know all of it.</h2>
										<p><a class="btn btn-primary btn-learn">View Portfolio <i class="icon-briefcase3"></i></a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</section> -->

	<section id="home" class="colorlib-about" data-section="home">
		<div class="colorlib-narrow-content">
			<div class="row">
				<div class="col-md-12">
					<div class="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
						<div class="col-md-12">
							<div class="about-desc">
								<span class="heading-meta">About Me</span>
								<h2 class="colorlib-heading">Who Am I?</h2>
								<p><strong>Hi I'm Tanyo Ivanov</strong> I used C oriented languages since 2008 and started my first work at 2010. It was a complicated path as the companies were just establishing
									in my hometown Plovdiv back then, but also there was a transition in .NET from closed source to open sourced framework.
									I worked on small and extremely large projects, small and large companies, in foreign countries and from my hometown too,
									on software project, but also on hardware projects. After my 8 hours long day I definitely stay for at least 3-4 hours
									most of the days to improve and educate myself, or work on something that brings me joy.
									The things that brings me joy for now are: software design, automated testing, 3D printing, severs management
									and infrastructure improvements.</p>
								<p>On my blog you can find some of the things that I make after work in my free time.
									In my portfolio you can find a brief explanation of projects that I worked on and some own
									creations. They are free to download and use.</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3 animate-box" data-animate-effect="fadeInLeft">
							<div class="services color-1">
								<span class="icon2"><i class="icon-bulb"></i></span>
								<h3>Software Analisys and Design</h3>
							</div>
						</div>
						<div class="col-md-3 animate-box" data-animate-effect="fadeInRight">
							<div class="services color-2">
								<span class="icon2"><i class="icon-globe-outline"></i></span>
								<h3>Software Infrastructure Development</h3>
							</div>
						</div>
						<div class="col-md-3 animate-box" data-animate-effect="fadeInTop">
							<div class="services color-3">
								<span class="icon2"><i class="icon-data"></i></span>
								<h3>Software Development</h3>
							</div>
						</div>
						<div class="col-md-3 animate-box" data-animate-effect="fadeInBottom">
							<div class="services color-4">
								<span class="icon2"><i class="icon-phone3"></i></span>
								<h3>IoT, Servers, Containerisation</h3>
							</div>
						</div>
					</div>
					<!-- <div class="row">
									<div class="col-md-12 animate-box" data-animate-effect="fadeInLeft">
										<div class="hire">
											<h2>I am happy to know you <br>that 300+ projects done sucessfully!</h2>
											<a href="#" class="btn-hire">Hire me</a>
										</div>
									</div>
								</div> -->
				</div>
			</div>
		</div>
	</section>

	<section id="services" class="colorlib-services" data-section="services">
		<div class="colorlib-narrow-content">
			<div class="row">
				<div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span class="heading-meta">What I do?</span>
					<h2 class="colorlib-heading">Here are some of my expertise</h2>
				</div>
			</div>
			<div class="row row-pt-md">
				<div class="col-md-4 text-center animate-box">
					<div class="services color-1">
						<span class="icon">
							<i class="icon-bulb"></i>
						</span>
						<div class="desc">
							<h3>Software Analysis</h3>
							<p>I can check your software project and prodce a list of possible improvements.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center animate-box">
					<div class="services color-2">
						<span class="icon">
							<i class="icon-data"></i>
						</span>
						<div class="desc">
							<h3>Development</h3>
							<p>I can use the dotnet and a few more platforms to develop software or features.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center animate-box">
					<div class="services color-3">
						<span class="icon">
							<i class="icon-phone3"></i>
						</span>
						<div class="desc">
							<h3>Containerisation</h3>
							<p>I can work with Infrastructure as Code languages and Docker to move your application to containers.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center animate-box">
					<div class="services color-4">
						<span class="icon">
							<i class="icon-layers2"></i>
						</span>
						<div class="desc">
							<h3>Front-End Layer</h3>
							<p>I can work with the framework layer and Single-Page frameworks/libraries to create front-end layer</p>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center animate-box">
					<div class="services color-5">
						<span class="icon">
							<i class="icon-data"></i>
						</span>
						<div class="desc">
							<h3>CMS</h3>
							<p>I can work with CMS-es like Umbraco, Wordpress, DotNetNuke and others to quickly bring an application on production.</p>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-center animate-box">
					<div class="services color-6">
						<span class="icon">
							<i class="icon-phone3"></i>
						</span>
						<div class="desc">
							<h3>Hardware</h3>
							<p>I can connect servers, think of distribution, plan resource usage and diagnose application resources usage.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<div id="colorlib-counter" class="colorlib-counters" style="background-image: url(images/cover_bg_1.jpg);" data-stellar-background-ratio="0.5">
		<div class="overlay"></div>
		<div class="colorlib-narrow-content">
			<div class="row">
			</div>
			<div class="row">
				<div class="col-md-3 text-center animate-box">
					<span class="colorlib-counter js-counter" data-from="0" data-to="2010" data-speed="5000" data-refresh-interval="50">2010</span>
					<span class="colorlib-counter-label">Works since</span>
				</div>
				<div class="col-md-3 text-center animate-box">
					<span class="colorlib-counter js-counter" data-from="0" data-to="18" data-speed="5000" data-refresh-interval="50">18</span>
					<span class="colorlib-counter-label">Projects</span>
				</div>
				<div class="col-md-3 text-center animate-box">
					<span class="colorlib-counter js-counter" data-from="0" data-to="5" data-speed="5000" data-refresh-interval="50">5</span>
					<span class="colorlib-counter-label">Clients</span>
				</div>
				<div class="col-md-3 text-center animate-box">
					<span class="colorlib-counter js-counter" data-from="0" data-to="30" data-speed="5000" data-refresh-interval="50">30</span>
					<span class="colorlib-counter-label">Technologies</span>
				</div>
			</div>
		</div>
	</div>

	<section id="skills" class="colorlib-skills" data-section="skills">
		<div class="colorlib-narrow-content">
			<div class="row">
				<div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span class="heading-meta">My Specialty</span>
					<h2 class="colorlib-heading animate-box">My Skills</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 animate-box" data-animate-effect="fadeInLeft">
					<p>Here is a brief overview of some of the most important skills and tehcnologies that I used in my path. </p>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
					<div class="progress-wrap">
						<h3>DotNet</h3>
						<div class="progress">
							<div class="progress-bar color-1" role="progressbar" aria-valuenow="90"
								 aria-valuemin="0" aria-valuemax="100" style="width:90%">
								<span>90%</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
					<div class="progress-wrap">
						<h3>SPA Libraries</h3>
						<div class="progress">
							<div class="progress-bar color-2" role="progressbar" aria-valuenow="85"
								 aria-valuemin="0" aria-valuemax="100" style="width:85%">
								<span>85%</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
					<div class="progress-wrap">
						<h3>Infrastructure</h3>
						<div class="progress">
							<div class="progress-bar color-3" role="progressbar" aria-valuenow="85"
								 aria-valuemin="0" aria-valuemax="100" style="width:85%">
								<span>85%</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
					<div class="progress-wrap">
						<h3>Databases</h3>
						<div class="progress">
							<div class="progress-bar color-4" role="progressbar" aria-valuenow="75"
								 aria-valuemin="0" aria-valuemax="100" style="width:75%">
								<span>75%</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
					<div class="progress-wrap">
						<h3>CMS-es</h3>
						<div class="progress">
							<div class="progress-bar color-5" role="progressbar" aria-valuenow="70"
								 aria-valuemin="0" aria-valuemax="100" style="width:70%">
								<span>70%</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
					<div class="progress-wrap">
						<h3>Cloud</h3>
						<div class="progress">
							<div class="progress-bar color-6" role="progressbar" aria-valuenow="75"
								 aria-valuemin="0" aria-valuemax="100" style="width:75%">
								<span>75%</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col"></div>
				<div class="col text-center">
					<p>
						<strong><a href="https://docs.google.com/spreadsheets/d/1DfRDGLRs4OrS5XtLKV67cEcAAtS7ar9B6Bw4M10OB9s/edit?usp=sharing" target="_blank">ALL SKILLS</a></strong>
						<br />
						<span>View full list with skills</span>
					</p>
				</div>
				<div class="col"></div>
			</div>
		</div>
	</section>

	<section id="education" class="colorlib-education" data-section="education">
		<div class="colorlib-narrow-content">
			<div class="row">
				<div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span class="heading-meta">Education</span>
					<h2 class="colorlib-heading animate-box">Education</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 animate-box" data-animate-effect="fadeInLeft">
					<div class="fancy-collapse-panel">
						<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
							<div class="panel panel-default">
								<div class="panel-heading" role="tab" id="headingThreeHeading">
									<h4 class="panel-title">
										<a class="collapse" @click="toggle('certifications')" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="certifications">Certifications
										</a>
									</h4>
								</div>
								<div id="certifications" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
									<div class="panel-body">
										<p>I already worked from the start of my Bachelor degree and back then I had to be on a long-term project in Sweden so I decided to not take a Master degree as I saw it as uneccessary after the Bachelor degree. I concentrated on out of work studies and took numerous certifications in different areas.
											<!-- <p>
														Here are some of them. You can download them:<br />
													<ul>
														<li>
															<a href="" target="_blank"></a>
														</li>
													</ul> -->
										</p>
									</div>
								</div>
							</div>

							<div class="panel panel-default">
								<div class="panel-heading" role="tab" id="headingFourHeading">
									<h4 class="panel-title">
										<a class="collapse" @click="toggle('university')" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="university">Bachelor - Business Information Technologies
										</a>
									</h4>
								</div>
								<div id="university" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
									<div class="panel-body">
										<p>After the high-school I started to learn Business Information Technologies in the university of Plovdiv. Back then I already started my first job, so I was learning and working in the same time. It was a 50/50 mix of Programing and Economics. I was learning 2 disciplines of Software Development and 2 disciplines of bussiness studies. They were preparing us for positions like Business Analyst who can understand the requirements but in the same time can work with code. I was the only one in the country who entered with 100% marks 6.00 test of programming. I graduated with excelent score too as one of the few people with excelent scores.</p>
									</div>
								</div>
							</div>

							<div class="panel panel-default">
								<div class="panel-heading" role="tab" id="highSchoolHeading">
									<h4 class="panel-title">
										<a class="collapse" @click="toggle('highSchool')" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="highSchool">High School - Information Technologies
										</a>
									</h4>
								</div>
								<div id="highSchool" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
									<div class="panel-body">
										<p>Many, many yers ago I was in a high-school and i was learning software development and technologies there. Very old languages as Turbo Pascal, Visual Basic, C etc. Very old technologies as Microsoft Office 2003, Flash, Corel Draw, etc. However, I was passionate about 3D Design as the last 6 years I was winning the painging contest each year. That's why I learned 3D Studio Max, Google SketchUp, K3D and some more and created large library with 3D models on a CD (that i lost after some years before saving it in the cloud).</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section id="experience" class="colorlib-experience" data-section="experience">
		<div class="colorlib-narrow-content">
			<div class="row">
				<div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span class="heading-meta">Experience</span>
					<h2 class="colorlib-heading animate-box">Work Experience</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="timeline-centered">
						<article class="timeline-entry animate-box" data-animate-effect="fadeInLeft">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-1">
									<i class="icon-pen2"></i>
								</div>
								<div class="timeline-label">
									<h2><a href="https://activtrades.com">Software Engineer</a> <span>Mar 2023 - Present</span></h2>
									<p>Migration of legacy dotnet app to another architecture and newer stack. Adding features and improvements.</p>
								</div>
							</div>
						</article>

						<article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-2">
									<i class="icon-pen2"></i>
								</div>
								<div class="timeline-label">
									<h2><a href="https://www.lexisnexis.com/en-us">Software Engineer III in LexisNexis UK</a> <span>Oct 2020-Dec 2021</span></h2>
									<p>
										Contributed to the development of a modern Global Tax platform with load of dependencies to different micro-services. <br />
										Cloud based delivery, most-modern at the time methodology of work, React Clients connected to multiple APIs.<br />
										<br />
										Skills: Terraform · Kubernetes · React Test Library · .NET Core · AWS · React.js · Jest · Agile Methodologies · ASP.NET · NUnit · React Hooks · Docker · SASS · Git
									</p>
								</div>
							</div>
						</article>

						<article class="timeline-entry animate-box" data-animate-effect="fadeInLeft">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-3">
									<i class="icon-pen2"></i>
								</div>
								<div class="timeline-label">
									<h2><a href="https://fosterandpartners.com">Application Developer in Foster + Partners</a> <span>Jan 2019-Jan 2020</span></h2>
									<p>
										- Worked on the development and testing of the Intranet portal and all the accompanying application inside it. <br />
										- An important part of the agile meetings. Tickets handling, bug fixing and features development. <br />
										<br />
										Skills: SQL · WinForms · Agile Environment · xUnit · Microsoft Azure · .NET Core · Load Testing · Umbraco · Vue.js · ASP.NET · Bootstrap · Selenium · jQuery · Windows Server · Entity Framework (EF) Core · TDD · SASS · Git
									</p>
								</div>
							</div>
						</article>

						<article class="timeline-entry animate-box" data-animate-effect="fadeInTop">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-4">
									<i class="icon-pen2"></i>
								</div>
								<div class="timeline-label">
									<h2><a href="https://www.bgosoftware.com/">Senior .NET Developer in BGO Software</a> <span>Jun 2016-Nov 2018</span></h2>
									<p>
										- Coordinating the work with the Project Manager and training a junior for a few months. <br />
										- Maintained the internal health research portal of the NHS. New features, bug fixing and handle tickets.<br />
										- Everyday communication with the Government team <br />
										<br />
										Skills: SQL · Windows Services · Unit Testing · Agile Methodologies · Entity Framework · Bootstrap · jQuery · Windows Server · Ace Admin · ASP.NET Core · Test Automation · Git
									</p>
								</div>
							</div>
						</article>

						<article class="timeline-entry animate-box" data-animate-effect="fadeInLeft">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-5">
									<i class="icon-pen2"></i>
								</div>
								<div class="timeline-label">
									<h2><a href="https://comstream.eu/">Software Engineer in ComStream</a> <span>Jun 2015-Jun 2016</span></h2>
									<p>
										- Built mobile Installer for a smart home gadget onsite in the Swedish office. <br />
										- Coordinated the work between me and a junior. <br />
										- Attended in everyday communication with the project manager. <br />
										<br />
										Skills: SQL · Windows Services · Entity Framework · ASP.NET · Bootstrap · jQuery · ASP.NET Web API · .NET Framework · Windows Server · Git
									</p>
								</div>
							</div>
						</article>

						<article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-2">
									<i class="icon-pen2"></i>
								</div>
								<div class="timeline-label">
									<h2><a href="https://www.bucons.com">DotNet Developer in Bucons</a> <span>Jan 2013-Jun 2015</span></h2>
									<p>
										- WinForm module for a Swiss University custom web shop using ASP.NET MVC<br />
										- Found and trained a Junior. <br />
										- Developed and delivered on time standalone Win services, Web services and libraries. <br />
										- WinForm module for a Swiss University custom web shop using ASP.NET MVC - Found and trained a Junior. - Developed and delivered on time standalone Win services, Web services and libraries. <br />
										<br />
										Skills: SQL · WPF · DotNetNuke (DNN) · WinForms · Windows Services · AWS · Entity Framework · ASP.NET · XBAP · Bootstrap · jQuery · ASP.NET Web API · .NET Framework · Windows Server · Git
									</p>
								</div>
							</div>
						</article>

						<article class="timeline-entry animate-box" data-animate-effect="fadeInTop">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-4">
									<i class="icon-pen2"></i>
								</div>
								<div class="timeline-label">
									<h2><a href="https://www.unrealsoft.net/">Database Engineer</a> <span>Jul 2012-Jan 2013</span></h2>
									<p>
										Web application for phone calls reports and stats.<br />
										Contribution to a GPS automobile tracking system.<br />
										Web application for phone calls reports and stats. Contribution to a GPS automobile tracking system. <br />
										<br />
										Skills: ASP.NET MVC · Firebird · jQuery · .NET Framework · Kendo Ui
									</p>
								</div>
							</div>
						</article>

						<article class="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
							<div class="timeline-entry-inner">
								<div class="timeline-icon color-none">
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- 
				<section class="colorlib-work" data-section="work">
					<div class="colorlib-narrow-content">
						<div class="row">
							<div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span class="heading-meta">My Work</span>
								<h2 class="colorlib-heading animate-box">Recent Work</h2>
							</div>
						</div>
						<div class="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
							<div class="col-md-12">
								<p class="work-menu"><span><a href="#" class="active">Graphic Design</a></span> <span><a href="#">Web Design</a></span> <span><a href="#">Software</a></span> <span><a href="#">Apps</a></span></p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
								<div class="project" style="background-image: url(images/img-1.jpg);">
									<div class="desc">
										<div class="con">
											<h3><a href="work.html">Work 01</a></h3>
											<span>Website</span>
											<p class="icon">
												<span><a href="#"><i class="icon-share3"></i></a></span>
												<span><a href="#"><i class="icon-eye"></i> 100</a></span>
												<span><a href="#"><i class="icon-heart"></i> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
								<div class="project" style="background-image: url(images/img-2.jpg);">
									<div class="desc">
										<div class="con">
											<h3><a href="work.html">Work 02</a></h3>
											<span>Animation</span>
											<p class="icon">
												<span><a href="#"><i class="icon-share3"></i></a></span>
												<span><a href="#"><i class="icon-eye"></i> 100</a></span>
												<span><a href="#"><i class="icon-heart"></i> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 animate-box" data-animate-effect="fadeInTop">
								<div class="project" style="background-image: url(images/img-3.jpg);">
									<div class="desc">
										<div class="con">
											<h3><a href="work.html">Work 03</a></h3>
											<span>Illustration</span>
											<p class="icon">
												<span><a href="#"><i class="icon-share3"></i></a></span>
												<span><a href="#"><i class="icon-eye"></i> 100</a></span>
												<span><a href="#"><i class="icon-heart"></i> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 animate-box" data-animate-effect="fadeInBottom">
								<div class="project" style="background-image: url(images/img-4.jpg);">
									<div class="desc">
										<div class="con">
											<h3><a href="work.html">Work 04</a></h3>
											<span>Application</span>
											<p class="icon">
												<span><a href="#"><i class="icon-share3"></i></a></span>
												<span><a href="#"><i class="icon-eye"></i> 100</a></span>
												<span><a href="#"><i class="icon-heart"></i> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
								<div class="project" style="background-image: url(images/img-5.jpg);">
									<div class="desc">
										<div class="con">
											<h3><a href="work.html">Work 05</a></h3>
											<span>Graphic, Logo</span>
											<p class="icon">
												<span><a href="#"><i class="icon-share3"></i></a></span>
												<span><a href="#"><i class="icon-eye"></i> 100</a></span>
												<span><a href="#"><i class="icon-heart"></i> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
								<div class="project" style="background-image: url(images/img-6.jpg);">
									<div class="desc">
										<div class="con">
											<h3><a href="work.html">Work 06</a></h3>
											<span>Web Design</span>
											<p class="icon">
												<span><a href="#"><i class="icon-share3"></i></a></span>
												<span><a href="#"><i class="icon-eye"></i> 100</a></span>
												<span><a href="#"><i class="icon-heart"></i> 49</a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 animate-box">
								<p><a href="#" class="btn btn-primary btn-lg btn-load-more">Load more <i class="icon-reload"></i></a></p>
							</div>
						</div>
					</div>
				</section> -->

	<section id="blog" class="colorlib-blog" data-section="blog">
		<div class="colorlib-narrow-content">
			<div class="row">
				<div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span class="heading-meta">Read</span>
					<h2 class="colorlib-heading">Recent Blog</h2>
				</div>
			</div>
			<div class="row">
				<div v-for="(blogItem, key) in blogItems" :key="key" class="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
					<div class="blog-entry">
						<router-link :to="'/blog/' + blogItem.name" class="blog-img"><img :src="blogItem.image" class="img-responsive" :alt="blogItem.title"></router-link>
						<div class="desc">
							<span><small>{{ moment(blogItem.created).format('DD MMM YYYY') }} </small> | <small> {{ blogItem.title }} </small></span>
							<h3><router-link :to="'/blog/' + blogItem.name">{{ blogItem.title }}</router-link></h3>
							<p>{{ blogItem.content }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 animate-box">
					<p>
						<router-link class="btn btn-primary btn-lg btn-load-more" to="/blog">View More <i class="icon-reload"></i></router-link>
					</p>
				</div>
			</div>
		</div>
	</section>

	<section id="contact" class="colorlib-contact" data-section="contact">
		<div class="colorlib-narrow-content">
			<div class="row">
				<div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span class="heading-meta">Get in Touch</span>
					<h2 class="colorlib-heading">Contact</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5">
					<div class="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
						<div class="colorlib-icon">
							<i class="icon-globe-outline"></i>
						</div>
						<div class="colorlib-text">
							<p><a href="mailto:tivanov@takerman.net">tivanov@takerman.net</a></p>
						</div>
					</div>

					<div class="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
						<div class="colorlib-icon">
							<i class="icon-map"></i>
						</div>
						<div class="colorlib-text">
							<p>
								<a href="https://www.linkedin.com/in/tanyo-ivanov/" target="_blank">LinkedIn</a> |
								<a href="https://github.com/Takerman" target="_blank">GitHub</a>
							</p>
						</div>
					</div>
				</div>
				<div class="col-md-7 col-md-push-1">
					<div class="row">
						<div class="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
							<form action="">
								<div class="form-group">
									<input type="text" v-model="name" class="form-control" placeholder="Name">
								</div>
								<div class="form-group">
									<input type="text" v-model="from" class="form-control" placeholder="Email">
								</div>
								<div class="form-group">
									<input type="text" v-model="subject" class="form-control" placeholder="Subject">
								</div>
								<div class="form-group">
									<textarea name="" v-model="body" id="message" cols="30" rows="7" class="form-control" placeholder="Message"></textarea>
								</div>
								<div class="form-group">
									<input type="button" @click="send()" class="btn btn-primary btn-send-message" value="Send Message">
								</div>
							</form>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="js">
import moment from 'moment';

export default {
	data() {
		return {
			name: '',
			from: '',
			subject: '',
			body: '',
			blogItems: [],
			moment: moment
		}
	},
	async created() {
		this.blogItems = await (await fetch('/Blog/GetBlogposts')).json();
		this.blogItems = this.blogItems.slice(0, 3);
	},
	methods: {
		async getBlogpost(path) {
			return await (await fetch(path)).text();
		},
		toggle(id) {
			let pannels = document.getElementsByClassName('panel-collapse');
			for (let i = 0; i < pannels.length; i++) {
				const pannel = pannels[i];
				if (!pannel.classList.contains('collapse'))
					pannel.classList.add('collapse');
			}
			let element = document.getElementById(id);
			if (element.classList.contains('collapse')) {
				element.classList.remove('collapse');
			} else {
				pannel.classList.add('collapse');
			}
		},
		async send() {
			try {
				await fetch('Home/SendMessage', {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						name: this.name,
						from: this.from,
						subject: this.subject,
						body: this.body,
						to: 'tivanov@takerman.net'
					})
				});
				alert("Send sucessfully");
			} catch (error) {
				console.log("Error: " + error);
			}
		}
	},
}
</script>

<style scoped></style>